import React from "react";
import item1 from "../assets/IMG_8236.JPG";
import item2 from "../assets/IMG_8241.JPG";
import item3 from "../assets/IMG_8253.JPG";
import item4 from "../assets/IMG_8242.JPG";
import item5 from "../assets/IMG_8251.JPG";
import item6 from "../assets/IMG_8252.JPG";
import item7 from "../assets/IMG_8250.JPG";
import item8 from "../assets/IMG_8238.JPG";
import item9 from "../assets/IMG_8247.JPG";

function ImageSlider2() {
  return (
    <div>
      {" "}
      <div className="relative w-full px-16   ">
        <div className="flex  animate-marquee-left">
          <div className="flex mx-2 h-64 justify-around">
            <img
              src={item1}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item2}
              className="rounded-lg  drop-shadow-xl object-contain m-5  "
              alt=""
            />
            <img
              src={item3}
              className="rounded-lg   drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item4}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item5}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item6}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item7}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item8}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item9}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item5}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item1}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item2}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item3}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item4}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
            <img
              src={item5}
              className="rounded-lg  drop-shadow-xl object-contain m-5 "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSlider2;
