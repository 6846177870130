import React from "react";
import logoImg from "../assets/IMG_8236.JPG";

function AboutUs() {
  return (
    <div className="my-10">
      <div className="text-6xl font-bold text-center md:text-left ml-16 ">
        ስለ ገዳሙ
        <div className="pt-1 pb-4 mb-3 ">
          <div className="md:w-24 w-20 border-t-8 rounded-full border-blue-500"></div>
        </div>
      </div>

      {/* <figure className="relative max-w-sm mx-auto   transition-all duration-300 cursor-pointer filter  hover:grayscale grayscale-0">
        <a href="#">
          <img
            className="h-60 sm:h-60 mx-auto"
            src={`${logoImg}`}
            alt="image description"
          />
        </a>
      </figure> */}

      <div className="w-[80vw] mx-auto rounded-lg md:p-16 p-4 shadow-md bg-white border border-white">
        ገዳሟ ከወረዳው ከተማ ሠላድንጋይ በስተምስራቅ አቅጣጫ በእግር ጉዞ የ10 ደቂቃ ርቀት ላይ ትገኛለች፡፡በአጼ ዘርዓ
        ያዕቆብ አማካኝነት የተመሰረተች ገዳም ናት፡፡ በአፄ ዘርዓ ያዕቆብ ዘመነ መንግስት ቱርኮች ግብፅን ወረው
        ክርስቲያኑን ፈጅተው በዚያው የሚገኙትን አብያተ ክርስቲያናትን ባቃጠሉና በፈጁት ጊዜ በወቅቱ ከተቃጠሉት አብያተ
        ክርስቲያናት መካከል አንዷ የሆነችው የደብረ ምጥማቅ ቤተ ክርስቲያን ነበረች፡፡ ከወረራውና ከቃጠሎው በፊት
        አስቀድመው ቀሳውስቱ ጽላቷን አውጥተውና ሸሽገው አስቀምጠዋት ስለነበር በጊዜው አባ ሚካኤልና አባ ገብርኤል የሚባሉ
        መነኩሳት ከብዙ ተከታዮቻቸው ጋር በሱዳን በኩል አድረገው ወደሃገራችን አምጥተው ጽላቷን ለአጼ ዘረያይቆብ
        እንዳስረከቡ ከገዳሟ የተገኙት ምንጮች ያስረዳሉ፡፡ ንጉሱም ጽላቷን ተቀብለው ጅባት ከዚያም አንጦጦ፣በበረህ ተራራ
        ላይ፣ደብረ-ብርሃንና በሌላም ስፍራ በማዘዋወር አስቀምጠዋታል፡፡ ከዚያ በኋላ አፄዘረያይቆብ በተገለፀላቸው ራዕይ
        መሰረት አሁን ደብረ ምጥማቅ እየተባለ በሚጠረው ስፍራ ቤተ ክርስቲያን አሳንጸው የቤተክርስቲያኒቱም ስም ግብጽ ባለው
        መጠሪያዋ ማስታወሻ ይሆን ዘንድ ደብረ ምጥማቅ ተብላለች፡፡ከብዙ ዘመን በኋላ ግራኝ መሀመድ የኢትዮጵያ አብያተ
        ክርስቲያናትንና ገዳማትን ሲያቃጥል ከተቃጠሉት ውስጥ አንዷ ይህች ቤተ ክርስቲያን ነበረች፡፡ሆኖም ከወረራው በፊት
        አጼ ዘርዓ ያዕቆብ በራይዕ በተገለፀለቸው መሰረት የቤተ ክርስቲያኑን ቅርስና ንብረት አሁን ፃድቃኔ ማርያም መካነ
        ቅዱሳን ገዳም በሚባለው ዋሻ ውስጥ ሰውረውታል፡፡ በኋላም በተቃጠለችው ቤተክርስቲያን ሌላ ቤተክርስቲያን ተሰርቶ
        አገልግሎት ሲሰጥ ቆይቶ በእርጅና ምክንያት ፈርሶ ስለነበር ቀዳማዊ ኃይለሥላሴ አንድ ኢጣሊያዊ ማሃንዲስ ልከው
        በ1948 ዓ.ም ቤተከርስቲያኑን አሰርተውት ነበር፡፡በአሁኑም ሰዓት ከቆየታዋ የተነሳ በማርጀቷ ሌላ ቤተክርስቲያን
        ለማሰራት የግንባታ ፕላን ተዘጋጅቶ ቤተክርስቲያኗን ለመስራት በሂደት ላይ ይገኛል፡፡ይህች ቤተክርስቲያን /ገዳም ብዙ
        እድሜ ያስቆጠረች ብትሆንም ታላቅ እውቅና ያገኘችው ከ1990ዎቹ ጀምሮ ነው፡፡ ከ30 ሰዎች በማይበልጡ የተጀመርው
        የጉብኝት ጉዞ በአሁኑ ጊዜ በዓመት ከ150 ሺህ- 600 ሺህ ህዝብ በላይ ወደ ገዳሟ እንደሚመጣ መረጃዎች
        ይጠቁማሉ፡፡ የፃድቃኔ ማርያም መካነ ቅዱሳን ገዳም ዓመታዊ በዓላት መስከረም21፣ህዳር21፣ጥር2 1፣ ግንቦት21 እና
        ነሃሴ 16 ናቸው፡፡ በተለይም በግንቦትና በነሃሴ በሚከበረው በዓሏ ላይ የሚመጣው የህዝብ ቁጥር ከፍተኛውን ስፍራ
        ይይዛል፡፡በዚህ ቅዱስ ስፍራ በተለያዩ ደዌ የተያዙ ሰዎች ሲፈወሱ ማየትና መስማት ቦታውን እጅግ ታላቅ ያደርገዋል፡፡
      </div>
    </div>
  );
}

export default AboutUs;
