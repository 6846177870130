import React from "react";
import ImageSlide from "../components/ImageSlide";

function Donate() {
  return (
    <div className="min-h-[80vh] max-w-[99vw] overflow-x-hidden">
      <ImageSlide />
      <div className="w-[50vw] mx-auto h-full p-4 text-center bg-white border rounded-lg shadow-md sm:p-8 ">
        <h5 className="mb-2 text-4xl font-bold text-gray-900 ">ገዳሙን ለማገዝ</h5>
        <p className=" text-base text-gray-600 sm:text-lg ">የኢትዮጵያ ንግድ ባንክ</p>
        <p className="mb-5 text-base text-gray-600 sm:text-lg ">
          1000075736677
        </p>
      </div>
    </div>
  );
}

export default Donate;
