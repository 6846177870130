import React from "react";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";

function About() {
  return (
    <div>
      <AboutUs />

      <ContactUs />
    </div>
  );
}

export default About;
