import React from "react";

function Footer() {
  return (
    <div>
      <footer className=" bottom-0 left-0 z-20 p-4 w-full bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 ">
        <span className="text-sm text-gray-500 sm:text-center mx-auto ">
          © 2022 Design by .
          <a href="https://qignit.com/" className="hover:underline">
            Qignit
          </a>
        </span>
        {/* <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500  sm:mt-0">
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Licensing
            </a>
          </li>
          <li>
            <a href="#" className="hover:underline">
              Contact
            </a>
          </li>
        </ul> */}
      </footer>
    </div>
  );
}

export default Footer;
