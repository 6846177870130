import React, { useState } from "react";
import heroImg from "../assets/IMG_8242.JPG";
import logoImg from "../assets/tsadqane-mariyam.png";
import ico from "../assets/icons8-address-book-80.png";

function HomeHero(props) {
  return (
    <div>
      <section
        className="bg-white bg-cover  min-h-[80vh]"
        style={{
          backgroundImage: `url(${heroImg})`,
        }}
      >
        <div className="bg-gradient-to-r from-blue-600/[0.8] to-blue-600/[0.9] min-h-[80vh] ">
          <div className="py-8 px-4 m-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
            <figure className="relative max-w-sm mx-auto   transition-all duration-300 cursor-pointer filter  hover:grayscale grayscale-0">
              <a href="#">
                <img
                  className="h-60 sm:h-60 mx-auto"
                  src={`${logoImg}`}
                  alt="logo"
                />
              </a>
              {/* <figcaption className="absolute bottom-6 px-4 text-lg text-white">
                <p>
                  Do you want to get notified when a new component is added to
                  Flowbite?
                </p>
              </figcaption> */}
            </figure>
            <h1 className="mb-4 text-4xl font-extrabold  leading-none  md:text-5xl lg:text-6xl text-white">
              ደብረምጥማቅ ፃድቃኔ ማርያም ገዳም ድህረገጽ
            </h1>
            <p className="mb-8 text-lg font-normal text-gray-100 lg:text-xl sm:px-16 xl:px-48">
              ሰሜን ሸዋ ዞን በሞጃና ወደራ ወረዳ የምትገኘው የፃድቃኔ ማርያም መካነ ቅዱሳን አንድነት ገዳም
            </p>
            <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <a
                href="/about"
                className="inline-flex cursor-pointer justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                ስለ ገዳሙ
                <svg
                  className="ml-2 -mr-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="/contact"
                className="inline-flex cursor-pointer justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 bg-slate-200 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
              >
                <img className="mr-2 -ml-1 w-5 h-5" src={ico} />
                ያግኙን
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeHero;
