import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";

import HomeHero from "../components/HomeHero";
import ImageSlide from "../components/ImageSlide";
import ImageSlider2 from "../components/ImageSlider2";
import NavBar from "../components/NavBar";

function home() {
  return (
    <div>
      <div className="max-w-[99vw] overflow-x-hidden">
        <HomeHero />
        <ImageSlide />
        <AboutUs />
        <ImageSlider2 />
        <ContactUs />

        {/* <GoogleMaps /> */}
      </div>
    </div>
  );
}

export default home;
