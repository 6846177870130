import React, { useEffect, useState } from "react";
import logoImg from "../assets/tsadqane-mariyam.png";

function NavBar() {
  const [navbar, setNavbar] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
  });
  const changeBackground = () => {
    console.log(window.scrollY);
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const changeShow = () => {
    setShow(!show);
  };
  return (
    <nav
      className={
        navbar
          ? "bg-blue-600/[0.9] px-2 sm:px-4 py-2.5 sticky  w-full z-20 top-0 left-0 border-b backdrop-blur-lg border-blue-400"
          : "bg-blue-600/[0.9] px-2 sm:px-4 py-2.5 sticky  w-full z-20 top-0 left-0 border-b backdrop-blur-lg border-blue-400 "
      }
    >
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <a
          href="https://TsadkaneMaryamMonastry.com/"
          className="flex items-center"
        >
          <img
            src={`${logoImg}`}
            className="h-10 mr-3 sm:h-14"
            alt="Tsadkane Maryam"
          />
        </a>
        <div className="flex md:order-2">
          <button
            type="button"
            onClick={() => {
              window.location.href = "/donate";
            }}
            className="text-gray-800 transition-all duration-300 bg-gray-100 hover:bg-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 "
          >
            Donate
          </button>
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-100 rounded-lg md:hidden hover:bg-blue-600  "
            aria-controls="navbar-sticky"
            aria-expanded="false"
            onClick={() => {
              changeShow();
            }}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className={
            show
              ? "items-center justify-between   w-full md:flex md:w-auto md:order-1"
              : "items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          }
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50/[0.5] md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent bg-white ">
            <li>
              <a
                href="/"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 "
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0"
              >
                Contact
              </a>
            </li>
            {/* <li>
              <a
                href="#"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0"
              >
                Contact
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
